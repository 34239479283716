<template>
    <div class="channels-page">
        <CardComponent>
            <template #header>
                <h2 class="card-title text-h1">{{ $t('channelsPage.newChannel') }}</h2>
            </template>

            <template #body>
                <div class="list">
                    <div v-for="(item, index) in channelsStore.channelsList" :key="index" class="list-item">
                        <div class="item-info">
                            <img
                                class="channel-img"
                                :src="
                                    item.profile_picture_url
                                        ? item.profile_picture_url
                                        : require(`@/assets/icons/${item.type}-icon.svg`)
                                "
                                alt=""
                            />
                            {{ item.name }}
                        </div>
                        <div class="item-buttons">
                            <div class="delete-btn item-btn" @click="confirmDelete(item.id)">
                                <svg
                                    width="30"
                                    height="30"
                                    viewBox="0 0 30 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.375 25C8.75625 25 8.22675 24.7826 7.7865 24.3478C7.3455 23.9122 7.125 23.3889 7.125 22.7778V8.33333C6.80625 8.33333 6.53888 8.22704 6.32288 8.01444C6.10763 7.80111 6 7.53704 6 7.22222C6 6.90741 6.10763 6.64333 6.32288 6.43C6.53888 6.21741 6.80625 6.11111 7.125 6.11111H11.625C11.625 5.7963 11.733 5.53222 11.949 5.31889C12.1642 5.1063 12.4313 5 12.75 5H17.25C17.5688 5 17.8361 5.1063 18.0521 5.31889C18.2674 5.53222 18.375 5.7963 18.375 6.11111H22.875C23.1938 6.11111 23.4608 6.21741 23.676 6.43C23.892 6.64333 24 6.90741 24 7.22222C24 7.53704 23.892 7.80111 23.676 8.01444C23.4608 8.22704 23.1938 8.33333 22.875 8.33333V22.7778C22.875 23.3889 22.6549 23.9122 22.2146 24.3478C21.7736 24.7826 21.2437 25 20.625 25H9.375ZM9.375 8.33333V22.7778H20.625V8.33333H9.375ZM11.625 19.4444C11.625 19.7593 11.733 20.023 11.949 20.2356C12.1642 20.4489 12.4313 20.5556 12.75 20.5556C13.0687 20.5556 13.3361 20.4489 13.5521 20.2356C13.7674 20.023 13.875 19.7593 13.875 19.4444V11.6667C13.875 11.3519 13.7674 11.0878 13.5521 10.8744C13.3361 10.6619 13.0687 10.5556 12.75 10.5556C12.4313 10.5556 12.1642 10.6619 11.949 10.8744C11.733 11.0878 11.625 11.3519 11.625 11.6667V19.4444ZM16.125 19.4444C16.125 19.7593 16.233 20.023 16.449 20.2356C16.6643 20.4489 16.9312 20.5556 17.25 20.5556C17.5688 20.5556 17.8361 20.4489 18.0521 20.2356C18.2674 20.023 18.375 19.7593 18.375 19.4444V11.6667C18.375 11.3519 18.2674 11.0878 18.0521 10.8744C17.8361 10.6619 17.5688 10.5556 17.25 10.5556C16.9312 10.5556 16.6643 10.6619 16.449 10.8744C16.233 11.0878 16.125 11.3519 16.125 11.6667V19.4444Z"
                                        fill="#0081DC"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div class="businessList">
                        <div v-for="(business, index) in businessList" :key="index" class="list-item list-business">
                            <div class="">
                                <div class="item-info">
                                    <img src="@/assets/icons/business.png" alt="" style="border-radius: 0" />
                                    {{ business.name }}
                                </div>
                                <div
                                    v-for="(page, pagesIndex) in business.ownedPages"
                                    :key="pagesIndex"
                                    class="list-item-sub"
                                >
                                    <div class="item-info fp-page">
                                        <img src="@/assets/icons/facebook-icon.svg" alt="" />
                                        {{ page.name }}
                                    </div>
                                    <div v-if="page.instagram" class="item-info ig-acc">
                                        <img src="@/assets/icons/instagram-icon.svg" alt="" />
                                        {{ page.instagram.username }}
                                        <div class="item-buttons connect">
                                            <button
                                                class="btn-transparent item-btn"
                                                :disabled="page.channelExists"
                                                @click="connectChannel(page.id)"
                                            >
                                                {{
                                                    page.channelExists
                                                        ? $t('channelsPage.connected')
                                                        : $t('channelsPage.connect')
                                                }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <infinite-loading target=".list" :distance="10" @infinite="infiniteHandler">
                        <template #complete>
                            <div class="text-h3 text-center no-results">{{ $t('channelsPage.channelsLoaded') }}</div>
                        </template>
                    </infinite-loading>
                </div>
            </template>

            <template #footer>
                <div v-click-outside="hideDropdown" class="dropdown-wrap">
                    <div class="dropdown" :class="{ opened: dropdownOpened }">
                        <div class="dropdown-item" @click="addChannel">
                            <img src="@/assets/icons/instagram-icon.svg" alt="" />
                            <span class="text-h2">Instagram</span>
                        </div>
                        <!--                        <div class="dropdown-item" @click="addChannel">-->
                        <!--                            <img src="@/assets/icons/facebook-icon.svg" alt="" />-->
                        <!--                            <span class="text-h2">Facebook</span>-->
                        <!--                        </div>-->
                    </div>
                    <button class="add-channel-btn btn-transparent" @click="dropdownOpened = true">
                        + {{ $t('channelsPage.addNewChannel') }}
                    </button>
                </div>
            </template>

            <template #loader>
                <LoaderComponent v-if="channelsStore.loadingChannels" />
            </template>
        </CardComponent>
        <DeleteComponent
            v-if="showDeleteConfirmation"
            :item-id="itemId"
            :confirmation-title="$t('channelsPage.channel')"
            @hide-confirmation="hideConfirmation"
            @delete-item="deleteChannel"
        />
    </div>
</template>

<script>
import CardComponent from '@/components/general/CardComponent.vue'
import vClickOutside from 'click-outside-vue3'
import DeleteComponent from '@/components/general/DeleteComponent.vue'
import { useChannelsStore } from '@/stores/channelsStore'
import LoaderComponent from '@/components/general/LoaderComponent.vue'
import { find as lodashFind, filter as lodashFilter } from 'lodash'

export default {
    name: 'ChannelsPage',
    components: { LoaderComponent, DeleteComponent, CardComponent },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    setup() {
        const channelsStore = useChannelsStore()

        return { channelsStore }
    },
    data() {
        return {
            dropdownOpened: false,
            scriptLoaded: false,
            showDeleteConfirmation: false,
            itemId: null,
            businessList: [],
            accounts: [],
            token: '',
        }
    },
    watch: {
        'channelsStore.channelsList'(newValue) {
            this.businessList.forEach((business) => {
                business.ownedPages.forEach((page) => {
                    let channel = lodashFind(newValue, (channel) => {
                        return channel.type === 'instagram' && channel.name == page?.instagram?.username
                    })

                    page.channelExists = !!channel
                })
            })
        },
    },
    methods: {
        confirmDelete(id) {
            this.itemId = id
            this.showDeleteConfirmation = true
        },

        hideConfirmation() {
            this.showDeleteConfirmation = false
            this.itemId = null
        },

        hideDropdown() {
            this.dropdownOpened = false
        },

        connectChannel(pageId) {
            this.channelsStore.addChannel({
                pageId: pageId,
                auth_token: this.token,
                type: 'instagram',
            })
        },

        addChannel() {
            if (!this.scriptLoaded) {
                let scriptTag = document.createElement('script')

                scriptTag.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js')
                scriptTag.onload = this.facebookInit
                document.body.appendChild(scriptTag)
                this.scriptLoaded = true

                return
            }

            this.facebookLogin()
        },

        facebookInit() {
            let vm = this

            window.fbAsyncInit = function () {
                window.FB.init({
                    appId: process.env.VUE_APP_FB_APP_ID,
                    version: 'v18.0',
                })

                vm.facebookLogin()
            }
        },

        facebookLogin() {
            window.FB.login(
                (response) => {
                    if (response.status !== 'connected') {
                        return
                    }

                    this.token = response.authResponse?.accessToken

                    this.getBusinesses()
                },
                {
                    scope: 'instagram_basic,instagram_manage_messages,business_management,pages_show_list,pages_read_engagement,pages_manage_metadata',
                }
            )
        },

        getBusinesses() {
            window.FB.api('me/businesses', (response) => {
                if (response?.data) {
                    this.businessList = response.data
                    this.getListPages()
                }
            })
        },

        getListPages() {
            window.FB.api('me/accounts', (response) => {
                if (response?.data) {
                    this.accounts = response.data
                    this.getOwnedPages()
                }
            })
        },

        getOwnedPages() {
            let promises = this.businessList.map(
                (businessItem) =>
                    new Promise((resolve) => {
                        window.FB.api(businessItem.id + '/owned_pages', function (response) {
                            if (response?.data) {
                                businessItem['ownedPages'] = response.data
                            }
                            resolve()
                        })
                    })
            )

            Promise.all(promises).then(() => {
                this.filterBusinessPages()
            })
        },

        filterBusinessPages() {
            this.businessList.forEach((businessItem) => {
                businessItem.ownedPages = lodashFilter(businessItem.ownedPages, (ownedPage) => {
                    let listPage = lodashFind(this.accounts, (page) => ownedPage.id === page.id)

                    ownedPage['lPage'] = listPage

                    return !!listPage
                })
            })

            this.getInstagramAccounts()
        },

        getInstagramAccounts() {
            this.businessList.map((businessItem) => {
                return businessItem?.ownedPages?.map((ownedPage) => {
                    window.FB.api(
                        'me/?fields=instagram_business_account{username,name,profile_picture_url,id}',
                        { access_token: ownedPage.lPage.access_token },
                        (response) => {
                            let channel = lodashFind(
                                this.channelsStore.channelsList,
                                (channel) =>
                                    channel.type === 'instagram' &&
                                    channel.name == response?.instagram_business_account?.username
                            )

                            ownedPage.instagram = response?.instagram_business_account
                            ownedPage.channelExists = !!channel
                        }
                    )
                })
            })
        },

        deleteChannel(id) {
            this.channelsStore.deleteChannel(id)
        },

        async infiniteHandler($state) {
            const response = await this.channelsStore.loadChannels()

            if (response.length === 10) {
                $state.loaded()
            } else {
                $state.complete()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/common/colors';

.channels-page {
    width: 100%;
    height: 100%;
}

.item-info {
    display: flex;
    align-items: center;

    .channel-img {
        width: 3.5rem;
        height: 3.5rem;
        margin-right: 1rem;
        border-radius: 50%;
    }
}

.list-item-sub {
    padding-left: 2rem;

    .item-info.ig-acc {
        margin-left: 3rem;
    }

    .item-buttons.connect {
        margin-left: 1rem;
    }

    .item-btn {
        min-width: 11rem;
    }
}

.dropdown-wrap {
    position: relative;

    .dropdown {
        position: absolute;
        bottom: 6rem;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.1);
        border-radius: 1rem;
        overflow: hidden;
        max-height: 0;
        transition: all ease-in-out 0s;

        &.opened {
            max-height: 60rem;
            transition: all ease-in-out 0.5s;

            & + .add-channel-btn {
                background: $black;
                color: $white;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            padding: 1.6rem 1rem;
            border-bottom: 1px solid $grey-light;

            &:hover {
                cursor: pointer;
                background: $grey-light;
            }

            &:last-of-type {
                border-bottom: none;
            }

            img {
                margin-right: 1rem;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .add-channel-btn {
        width: 100%;
        max-width: 100%;
    }

    .list-item-sub {
        flex-direction: column;
        align-items: flex-start;

        .item-info.ig-acc {
            margin-top: 1rem;
        }
    }
}
</style>
